:root {
  --chosen-font: sans-serif;
}

header {
  display: flex;
  color: #54c45e;
  background-color: #282c33;
  text-align: center;
  height: 10vh;
  margin: none;
  padding: none;
  flex-grow: auto;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.nav--icon {
  height: 50px;
  align-self: center;
  padding: 15px;
  cursor: pointer;
}

body {
  font-family: var(--chosen-font);
  background-color: black;
}
h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 50px;
  margin-left: 15vh;
}

.nav--controls{
  display: flex;
  padding: 15px; 
}

.nav--search { grid-area: nav--search; }

.nav--button {
  line-height: 2rem;
  color: #000;
  background-color: #b1bdd6;
  height: 36px;
  width: 80px;
  margin-top: 0px;
  margin-left: 3px;
  padding-top: 5px;
  padding-right: 3px;
  border-radius: 10px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
}

.nav--button:hover {
  background-color: darkblue;
}

.clip--container{
  background-color: black;

  width: 100%;
  justify-self: center;
  align-content: center;
  text-align: center;
  overflow: hidden;
}


  .clip--audiocontrols{
    color: white;
    position: absolute;
    bottom: 80px;
    right: 10%;
    padding: 25px 25px;
    height: 10px;
    border: 1px solid black;
    background-color:rgba(51, 45, 45, 0.6);
    text-align: center;
  }

  .clip--text{
    color: white;
    position: absolute;
    bottom: 80px;
    left: 15%;
    border: 1px solid black;
    padding: 25px 25px;
    width: 200px;
    background-color:rgba(51, 45, 45, 0.6);
  }

  video
  {
    min-width: 70%;
    text-align: center;
    max-width: 100%;
    height: auto;
    min-height: 89.5vh;
    max-height: 89.5vh;
  }

  @media screen and (max-width: 768px) {
    h1 {
      margin-left: 0px;
    }
    video
    {
      width: auto;
      margin-top: 10vh;
      text-align: center;
      min-height: 100%;
      max-height: auto;
    }
    header {
      max-width: 100vw;
      height: 10vh;
      justify-content: center;
    }
    .nav--button {
      line-height: 2rem;
      color: #000;
      background-color: #b1bdd6;
      height: 36px;
      width: 75px;
      margin-top: 0px;
      padding-top: 5px;
      padding-left: 2px;
      padding-right: 2px;
      border-radius: 10px;
    }
    .clip--audiocontrols{
      color: white;
      position: absolute;
      bottom: 12vh;
      right: 0%;
      padding: 25px 25px;
      height: 10px;
      border: 1px solid black;
      background-color:rgba(51, 45, 45, 0.6);
      text-align: center;
    }
  
    .clip--text{
      color: white;
      position: absolute;
      bottom: 0px;
      left: 0%;
      border: 1px solid black;
      padding: 25px 25px;
      width: 200px;
      background-color:rgba(51, 45, 45, 0.6);
    }
  }


  @media screen and (min-width: 769px) and (max-width: 1300px) {
    video
    {
      width: auto;
      text-align: center;
      min-height: 100%;
      max-height: auto;
    }
    header {
      max-width: 100vw;
      height: 15vh;
      justify-content: space-between;
    }
    .nav--button {
      line-height: 2rem;
      color: #000;
      background-color: #b1bdd6;
      height: 36px;
      width: 75px;
      margin-top: 0px;
      padding-top: 5px;
      padding-left: 2px;
      padding-right: 2px;
      border-radius: 10px;
    }
    .clip--audiocontrols{
      color: white;
      position: absolute;
      bottom: 12vh;
      right: 0%;
      padding: 25px 25px;
      height: 10px;
      border: 1px solid black;
      background-color:rgba(51, 45, 45, 0.6);
      text-align: center;
    }
  
    .clip--text{
      color: white;
      position: absolute;
      bottom: 0px;
      left: 0%;
      border: 1px solid black;
      padding: 25px 25px;
      width: 200px;
      background-color:rgba(51, 45, 45, 0.6);
    }
  }